import Loading from "components/Loading";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import { MODULE_PROMOTION } from "shared/constants";
import Permission from "components/Permission";

const Promotion = React.lazy(
  () => import("containers/ServiceManagement/Promotion")
);

function PromotionRoute() {
  return (
    <Route
      path="promotion"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Promotion />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_PROMOTION]}
          />
        </Suspense>
      }
    />
  );
}

export default PromotionRoute;
