import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_PLAN } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const PlanConfig = React.lazy(() => import("containers/ConfigModule/Plan"));

function PlanConfigRoute() {
  return (
    <Route
      path="plan"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<PlanConfig />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_PLAN]}
          />
        </Suspense>
      }
    />
  );
}

export default PlanConfigRoute;
