const templateDataForImport = {
  // dictionary
  key: {
    vi: ["card"],
  },
  vi: {
    vi: ["Thẻ"],
  },
  en: {
    vi: ["card"],
  },

  ja: {},

  zh: {},

  ko: {},
};

export default templateDataForImport;
