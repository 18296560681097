import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const SURVEY_DEFAULT_FILTER = {
  title: undefined,
  status: undefined,
  unitGroupId: undefined,
  time: undefined,
};

export const RESULT_SURVEY_DEFAULT_FILTER = {
  unitGroupId: undefined,
  unitId: undefined,
};

export const SURVEY_STATUS_NOT_PUBLISH = "NOT_PUBLISH";
export const SURVEY_STATUS_PUBLISH = "PUBLISH";
export const SURVEY_STATUS_CANCEL = "CANCEL";

export const SURVEY_STATUS = [
  {
    value: SURVEY_STATUS_NOT_PUBLISH,
    name: language.not_publish,
    color: "yellow",
  },
  {
    value: SURVEY_STATUS_PUBLISH,
    name: language.published,
    color: "green",
  },
  {
    value: SURVEY_STATUS_CANCEL,
    name: language.cancelled,
    color: "red",
  },
];
export const SURVEY_STATUS_OBJ = convertArrToObj(SURVEY_STATUS, "value");

export const SURVEY_QUESTION_TYPE = "SCALES_OF_MEASUREMENT";
export const SURVEY_QUESTION_TYPE_CONTENT = "CONTENT";
export const SURVEY_QUESTION_TYPE_LIST = [
  {
    value: SURVEY_QUESTION_TYPE,
    name: language.scales_of_measurement,
  },
  {
    value: SURVEY_QUESTION_TYPE_CONTENT,
    name: language.content,
  },
];
export const SURVEY_QUESTION_TYPE_OBJ = convertArrToObj(
  SURVEY_QUESTION_TYPE_LIST,
  "id"
);

export const LABEL = "LABEL";
export const DASH_LINE = "DASH_LINE";
export const DIVIDER = "DIVIDER";

export const TEXT_FIELD = "TEXT_FIELD";
export const TEXT_AREA = "TEXT_AREA";
export const RADIO_GROUP = "RADIO_GROUP";
export const CHECKBOX = "CHECKBOX";
export const CHECKBOX_GROUP = "CHECKBOX_GROUP";
export const DROPDOWN = "DROPDOWN";
export const DATE_PICKER = "DATE_PICKER";
export const TIME_PICKER = "TIME_PICKER";
export const DATE_TIME_PICKER = "DATE_TIME_PICKER";

export const VALIDATE_REQUIRED = "required";
export const WIDGET_TYPE_VIEW = "VIEW";
export const WIDGET_TYPE_FORM = "FORM";
