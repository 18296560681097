import { language } from "shared/language";

export const COMMON_KEY = [
  {
    key: "bedroom",
    name: "Số phòng ngủ",
    enName: "Bedroom",
    type: "NUMBER",
  },
  {
    key: "bathroom",
    name: "Số phòng tắm",
    enName: "Bathroom",
    type: "NUMBER",
  },
  {
    key: "storey",
    name: "Số tầng",
    enName: "Amount floor",
    type: "NUMBER",
  },
  {
    key: "buildStatus",
    name: "Tình trạng xây dựng",
    enName: "Build status",
    type: "TEXT",
  },
  {
    key: "useArea",
    name: "Diện tích sử dụng",
    enName: "Usage area",
    type: "NUMBER",
  },
  {
    key: "balcony",
    name: "Số ban công",
    enName: "Balcony",
    type: "NUMBER",
  },
  {
    key: "localAddress",
    name: "Địa chỉ",
    enName: "Address",
    type: "TEXT",
  },
  {
    key: "window",
    name: "Số cửa sổ",
    enName: "Window",
    type: "NUMBER",
  },
  {
    key: "direction",
    name: "Hướng",
    enName: "Direction",
    type: "TEXT",
  },
];

export const TEXT = "TEXT";
export const NUMBER = "NUMBER";
export const DATE = "DATE";

export const ADDITIONAL_INFO_TYPES_ARRAY = [
  {
    id: TEXT,
    name: language.text,
  },
  {
    id: NUMBER,
    name: language.number,
  },
  {
    id: DATE,
    name: language.date,
  },
];

export const INVALID_KEY = ["enName", "code", "name", "area"];
