import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_ORGANIZATION } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const Organization = React.lazy(
  () => import("containers/ProjectManagement/Organization")
);

function OrganizationRoute() {
  return (
    <Route
      path="organizations"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Organization />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_ORGANIZATION]}
          />
        </Suspense>
      }
    />
  );
}

export default OrganizationRoute;
