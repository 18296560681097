import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const ICON_STATUS_ACTIVE = "ACTIVE";
export const ICON_STATUS_INACTIVE = "INACTIVE";
export const ICON_STATUS_MAINTENANCE = "MAINTENANCE";

export const ICON_TYPE_GENERAL = "GENERAL";
export const ICON_TYPE_UNIT_GROUP_SERVICE = "UNIT_GROUP_SERVICE";
export const ICON_TYPE_THIRD_PARTY_SERVICE = "THIRD_PARTY_SERVICE";
export const ICON_TYPE_FIN_VIET_SERVICE = "FIN_VIET_SERVICE";

export const ICON_DEFAULT_FILTER = {
  key: undefined,
  whiteLabelId: undefined,
  status: ICON_STATUS_ACTIVE,
  nameVi: undefined,
  appType: undefined,
  type: ICON_TYPE_UNIT_GROUP_SERVICE,
};

export const ICON_STATUS = [
  {
    id: ICON_STATUS_ACTIVE,
    name: language.status_active,
  },
  {
    id: ICON_STATUS_INACTIVE,
    name: language.status_inactive,
  },
  {
    id: ICON_STATUS_MAINTENANCE,
    name: language.status_maintenance,
  },
];

export const ICON_STATUS_BY_ID = convertArrToObj(ICON_STATUS, "id");

export const ICON_TYPES = [
  {
    id: ICON_TYPE_GENERAL,
    key: ICON_TYPE_GENERAL,
    name: language.general,
    with: "100px",
    height: "100px",
  },
  {
    id: ICON_TYPE_UNIT_GROUP_SERVICE,
    key: ICON_TYPE_UNIT_GROUP_SERVICE,
    name: language.unit_group_service,
    with: "87.5px",
    height: "103px",
  },
  {
    id: ICON_TYPE_THIRD_PARTY_SERVICE,
    key: ICON_TYPE_THIRD_PARTY_SERVICE,
    name: language.third_party_service,
    with: "152px",
    height: "71.66px",
  },
  {
    id: ICON_TYPE_FIN_VIET_SERVICE,
    key: ICON_TYPE_FIN_VIET_SERVICE,
    name: language.fin_viet_service,
    with: "87.5px",
    height: "103px",
  },
];
export const ICON_TYPE_BY_ID = convertArrToObj(ICON_TYPES, "id");
