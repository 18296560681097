export const COMMON_KEY_UNIT = [
  {
    key: "dateOfTakingApartment",
    name: "Ngày nhận căn hộ",
    enName: "Date of taking apartment",
    type: "DATE",
  }
];

export const INVALID_KEY_UNIT = ["enName", "code", "name", "area"];
