import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_MODULE } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const ModuleConfig = React.lazy(() => import("containers/ConfigModule/Module"));

function ModuleConfigRoute() {
  return (
    <Route
      path="premium"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<ModuleConfig />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_MODULE]}
          />
        </Suspense>
      }
    />
  );
}

export default ModuleConfigRoute;
