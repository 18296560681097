import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import { MODULE_PAYMENT_PROVIDER } from "shared/constants";

const PaymentProvider = React.lazy(
  () => import("containers/PaymentManagement/PaymentProvider")
);

const PaymentProviderRoute = () => {
  return (
    <Route
      path="payment-provider"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<PaymentProvider />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_PAYMENT_PROVIDER]}
          />
        </Suspense>
      }
    />
  );
};

export default PaymentProviderRoute;
