import { sample } from "lodash";
import templateDataForImport from "shared/constants/templateDataForImport";
import { getCacheLanguage } from "shared/language";

const TEMPLATE_DATA_SIZE = 3;

const generateTemplateData = (headers) => {
  const generatedData = [];
  const cacheLanguage = getCacheLanguage();

  for (let i = 0; i < TEMPLATE_DATA_SIZE; i += 1) {
    const rowData = {};

    headers.forEach((header) => {
      const { key, rules } = header;
      const { type } = rules;
      const languageData = templateDataForImport?.[key]?.[cacheLanguage];

      switch (type) {
        case "enum": {
          const enumValues = rules?.enum;
          rowData[key] =
            enumValues && enumValues?.length ? sample(enumValues) : "";
          break;
        }
        case "date":
        case "string":
        case "email":
        case "number": {
          rowData[key] =
            languageData && languageData?.length ? sample(languageData) : "";
          break;
        }
        default: {
          rowData[key] = "";
          break;
        }
      }
    });

    generatedData.push({ ...rowData, index: i + 1 });
  }

  return generatedData;
};

export default generateTemplateData;
