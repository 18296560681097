import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_SURVEY } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const Survey = React.lazy(() => import("containers/ProjectManagement/Survey"));

function SurveyRoute() {
  return (
    <Route
      path="survey"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Survey />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_SURVEY]}
          />
        </Suspense>
      }
    />
  );
}

export default SurveyRoute;
