import { language } from "shared/language";

export const PARTNER_SERVICE_CURRENTLY_IN_USE =
  language.partner_service_currently_in_use;

export const THIRD_PARTY_PARTNER_CODE_EXISTED =
  language.partner_code_already_existed;

export const THIRD_PARTY_ICON_HAS_PARTNER_ID =
  language.third_party_icon_has_partner_id;
