import { convertArrToObj } from "shared/utils";
import { language } from "../language";

export const CH_INSTALLATION = "ch_installation";
export const HTML_PRINT = "";
export const TIME_DEBOUNCE_CALL_API = 600;
export const OTHER = "OTHER";
export const DEFAULT_PAGE = 1;

export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const DISABLED = "DISABLED";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const MAINTENANCE = "MAINTENANCE";
export const STATUS = [
  {
    label: language.success,
    value: SUCCESS,
    color: "success",
  },
  {
    label: language.fail,
    value: FAILURE,
    color: "error",
  },
  {
    label: language.active,
    value: ACTIVE,
    color: "success",
  },
  {
    label: language.inactive,
    value: INACTIVE,
    color: "default",
  },
  {
    label: language.inactive,
    value: DISABLED,
    color: "default",
  },
];
export const STATUS_BUILDING = [
  {
    name: language.active,
    value: "ACTIVE",
  },
  {
    name: language.inactive,
    value: "DISABLED",
  },
];
export const STATUS_OBJ = convertArrToObj(STATUS, "value");

export * from "./responseCodeMapping";
export * from "./env";
export * from "./api";
export * from "./language";
export * from "./ga";
export * from "./permission";
export * from "./gender";
export * from "./building";
export * from "./user";
export * from "./module";
export * from "./action";
export * from "./payment";
export * from "./whiteLabel";
export * from "./icon";
export * from "./version";
export * from "./appMobile";
export * from "./notification";
export * from "./city";
export * from "./district";
export * from "./ward";
export * from "./banner";
export * from "./partner";
export * from "./unitGroups";
export * from "./appProjectConfig";
export * from "./bill";
export * from "./merchantOrder";
export * from "./merchantUtility";
export * from "./merchant";
export * from "./categoryHotDeal";
export * from "./whiteLabel";
export * from "./paymentProvider";
export * from "./dateTime";
export * from "./organization";
export * from "./survey";
export * from "./baseLocationType";
export * from "./regex";
export * from "./dictionary";
export * from "./configUnit";
export * from "./popup";
