import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import { MODULE_CONFIG_POPUP } from "shared/constants";

const Popup = React.lazy(() => import("containers/ConfigModule/ConfigPopup"));

const ConfigPopupRoute = () => {
  return (
    <Route
      path="config-popup"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Popup />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_CONFIG_POPUP]}
          />
        </Suspense>
      }
    />
  );
};

export default ConfigPopupRoute;
