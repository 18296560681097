import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";
import languageVI from "../../assets/images/vietnam.png";
import languageEN from "../../assets/images/english.png";
import languageJA from "../../assets/images/japan.png";
import languageZH from "../../assets/images/china.png";
import languageKO from "../../assets/images/korea.png";


export const NOTIFICATION_DEFAULT_FILTER = {
  userName: undefined,
  notificationType: undefined,
  unitGroupName: undefined,
  title: undefined,
  destId: undefined,
};

export const ADS_NOTICE = "ADS_NOTICE";
export const WEBVIEW_NOTICE = "WEBVIEW_NOTICE";
export const NORMAL_NOTICE = "NORMAL_NOTICE";
export const SURVEY_NOTICE = "SURVEY_NOTICE";
export const PROMOTION_NOTICE = "PROMOTION_NOTICE";

export const ELEVATOR_RESERVATION = "ELEVATOR_RESERVATION";
export const EZIN_INSURANCE = "EZIN_INSURANCE";
export const FEEDBACK = "FEEDBACK";
export const HOTLINE = "HOTLINE";
export const PROPERTY_POST = "PROPERTY_POST";
export const TRIPI_FLIGHT = "TRIPI_FLIGHT";
export const TRIPI_HOTEL = "TRIPI_HOTEL";
export const TRIPI_TOPUP = "TRIPI_TOPUP";
export const VISITOR = "VISITOR";
export const REE = "REE";

export const NOTIFICATION_TYPES = [
  { id: NORMAL_NOTICE, name: language.normal },
  { id: WEBVIEW_NOTICE, name: language.webView },
  { id: ADS_NOTICE, name: language.advertising },
  { id: SURVEY_NOTICE, name: language.survey },
  { id: PROMOTION_NOTICE, name: language.promotion },
  { id: ELEVATOR_RESERVATION, name: language.elevator_reservation },
  { id: EZIN_INSURANCE, name: language.ezin_insurance },
  { id: FEEDBACK, name: language.feedback },
  { id: HOTLINE, name: language.hotline },
  { id: VISITOR, name: language.visitor },
  { id: REE, name: language.ree },
];

export const NOTIFICATION_TYPE_BY_ID = convertArrToObj(
  NOTIFICATION_TYPES,
  "id"
);

export const CONFIG_NOTIFICATION_MULTI_LANGUAGE_VIETNAMESE = "VIETNAMESE";
export const CONFIG_NOTIFICATION_MULTI_LANGUAGE_ENGLISH = "ENGLISH";
export const CONFIG_NOTIFICATION_MULTI_LANGUAGE_JAPANESE = "JAPANESE";
export const CONFIG_NOTIFICATION_MULTI_LANGUAGE_CHINESE = "CHINESE";
export const CONFIG_NOTIFICATION_MULTI_LANGUAGE_KOREA = "KOREA";

export const CONFIG_NOTIFICATION_MULTI_LANGUAGE_LIST = [
  {
    name: language.vietnamese,
    id: CONFIG_NOTIFICATION_MULTI_LANGUAGE_VIETNAMESE,
    key: CONFIG_NOTIFICATION_MULTI_LANGUAGE_VIETNAMESE,
    image: languageVI,
  },
  {
    name: language.english,
    id: CONFIG_NOTIFICATION_MULTI_LANGUAGE_ENGLISH,
    key: CONFIG_NOTIFICATION_MULTI_LANGUAGE_ENGLISH,
    image: languageEN,
  },
  {
    name: language.japanese,
    id: CONFIG_NOTIFICATION_MULTI_LANGUAGE_JAPANESE,
    key: CONFIG_NOTIFICATION_MULTI_LANGUAGE_JAPANESE,
    image: languageJA,
  },
  {
    name: language.chinese,
    id: CONFIG_NOTIFICATION_MULTI_LANGUAGE_CHINESE,
    key: CONFIG_NOTIFICATION_MULTI_LANGUAGE_CHINESE,
    image: languageZH,
  },
  {
    name: language.korea,
    id: CONFIG_NOTIFICATION_MULTI_LANGUAGE_KOREA,
    key: CONFIG_NOTIFICATION_MULTI_LANGUAGE_KOREA,
    image: languageKO,
  },
];

export const CONFIG_NOTIFICATION_MULTI_LANGUAGE_OBJ = convertArrToObj(
  CONFIG_NOTIFICATION_MULTI_LANGUAGE_LIST,
  "value"
);

export const MULTI_LANGUAGE_NOTIFICATION: any = {
  [CONFIG_NOTIFICATION_MULTI_LANGUAGE_VIETNAMESE]: {
    languageKey: "VI",
    notificationTitle: language.title,
    notificationBody: language.content,
  },
  [CONFIG_NOTIFICATION_MULTI_LANGUAGE_ENGLISH]: {
    languageKey: "EN",
    notificationTitle: language.title,
    notificationBody: language.content,
  },
  [CONFIG_NOTIFICATION_MULTI_LANGUAGE_JAPANESE]: {
    languageKey: "JA",
    notificationTitle: language.title,
    notificationBody: language.content,
  },
  [CONFIG_NOTIFICATION_MULTI_LANGUAGE_CHINESE]: {
    languageKey: "ZH",
    notificationTitle: language.title,
    notificationBody: language.content,
  },
  [CONFIG_NOTIFICATION_MULTI_LANGUAGE_KOREA]: {
    languageKey: "KO",
    notificationTitle: language.title,
    notificationBody: language.content,
  },
};
