import * as BASE from "./base";
import * as API from "./api";
import * as AUTH from "./auth";
import * as ROLE from "./role";
import * as PERMISSION from "./permission";
import * as USER from "./user";
import * as BUILDING from "./building";
import * as VERSION from "./version";
import * as ICON from "./icon";
import * as WHITE_LABEL from "./whiteLabel";
import * as PARTNER from "./partner";
import * as MERCHANT_UTILITY from "./merchantUtility";
import * as CATEGORY_HOT_DEAL from "./categoryHotDeal";
import * as PAYMENT_PROVIDER from "./paymentProvider";
import * as MANAGEMENT from "./management";
import * as INVESTOR from "./investor";
import * as ORGANIZATION from "./organization";
import * as DICTIONARY from "./dictionary";
import * as CONFIG_UNIT from "./configUnit";

export const RESPONSE_MAPPING: {
  [key: string]: string;
} = {
  ...BASE,
  ...API,
  ...AUTH,
  ...ROLE,
  ...PERMISSION,
  ...USER,
  ...BUILDING,
  ...VERSION,
  ...ICON,
  ...WHITE_LABEL,
  ...PARTNER,
  ...MERCHANT_UTILITY,
  ...CATEGORY_HOT_DEAL,
  ...PAYMENT_PROVIDER,
  ...MANAGEMENT,
  ...INVESTOR,
  ...ORGANIZATION,
  ...DICTIONARY,
  ...CONFIG_UNIT
};

export * from "./base";
export * from "./auth";
export * from "./api";
export * from "./role";
export * from "./permission";
export * from "./user";
export * from "./building";
export * from "./icon";
export * from "./version";
export * from "./whiteLabel";
export * from "./partner";
export * from "./categoryHotDeal";
export * from "./paymentProvider";
export * from "./management";
export * from "./investor";
export * from "./organization";
export * from "./dictionary";
export * from "./configUnit";
